<template>
  <div class="gdpr-wrapper-page">
    <div class="content-wrapper widthfix">
      <div class="info">
        {{$t('footer.text-我们使用cookies为您提供更好、更安全的服务。一些cookie是使用和改进我们的服务所必需的，并通过单击“全部允许”或“')}}<span>{{$t('footer.text-管理cookie')}}</span>" {{$t('footer.text-同意')}} <a href="">{{$t('footer.text-Cookie宣告')}}</a>.
      </div>
      <div class="btn"
           @click="handcookies">{{$t('footer.text-允许所有')}}</div>
      <div class="refuse"
           @click="handcookies">{{$t('footer.text-非必要')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gdprWrapperPage',
  data () {
    return {
      istrue: true
    }
  },
  methods: {
    handcookies () {
      this.$store.commit('setting/SETSHOWCOOKIES', false)
    }
  }
}
</script>

<style lang="less" scoped>
.gdpr-wrapper-page {
  width: 100%;
  padding: 16px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  background: hsla(0, 0%, 100%, 0.8);
  box-shadow: 0 -12px 64px 0 rgba(154, 171, 233, 0.16);
  backdrop-filter: blur(20px);

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .info {
      flex: 1;
      font-size: 14px;
      color: #000;
      margin-right: 30px;

      span {
        color: #000;
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
      }

      a {
        color: #000;
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .btn {
      flex: 0 0 150px;
      padding: 0 16px;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #fff;
      background: #333;
      border-radius: 8px;
      cursor: pointer;
      margin-right: 12px;
      font-weight: 600;
      transition: transform 0.3s ease-in-out;
      text-align: center;
      &:hover {
        transform: translateY(-2px);
      }
    }

    .refuse {
      display: flex;
      flex: 0 0 240px;
      align-items: center;
      justify-content: center;
      height: 38px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      cursor: pointer;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #333;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    box-shadow: 0 -0.75rem 4rem 0 rgba(154, 171, 233, 0.16);
    backdrop-filter: blur(1.25rem);
    .content-wrapper {
      display: block;
      .info {
        margin-right: 0;
        font-size: 0.75rem;
        line-height: 1.375rem;
      }
      .btn {
        margin-right: 0;
        height: 2.75rem;
        line-height: 2.75rem;
        font-size: 0.875rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
        padding: 0 1rem;
      }
      .refuse {
        border: none;
        margin-top: 1rem;
        font-size: 0.875rem;
        height: auto;
        border-radius: 0;
      }
    }
  }
}
</style>
