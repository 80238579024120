import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import i18n from '../VueI18n'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index/:lang?',
    name: 'Home',
    component: () => import('@/views/home.vue')
  },
  {
    path: '/download/:lang?',
    name: 'Download',
    component: () => import('@/views/download.vue')
  },
  {
    path: '/aiSpell/:lang?',
    name: 'AiSpell',
    component: () => import('@/views/aiSpell.vue')
  },
  {
    path: '/about/:lang?',
    name: 'About',
    component: () => import('@/views/about.vue')
  },
  {
    path: '/privacy/:lang?',
    name: 'Privacy',
    component: () => import('@/views/privacy.vue')
  },
  {
    path: '/partner/:lang?',
    name: 'Partner',
    component: () => import('@/views/partner.vue')
  },
  { path: '*', redirect: '/' } // 添加通配符路由
]
// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { y: 0 }
  }
})
// 添加前置导航守卫
router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.params.lang) {
    // eslint-disable-next-line eqeqeq
    const obj = store.state.setting.langList.find((e) => e.key == to.params.lang)
    if (obj && obj.name) {
      i18n.locale = obj.key
      store.commit('setting/SETLANG', obj.key)
      store.commit('setting/SETLANGNAME', obj.name)
    }
  }
  // 动态设置标题
  document.title = process.env.VUE_APP_TITLE
  // to.meta.title ? document.title = process.env.VUE_APP_TITLE + ' - ' + to.meta.title : document.title = process.env.VUE_APP_TITLE
  // 普通情况，直接放行
  next()
})
export default router
