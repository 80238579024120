<template>
  <div id="app">
    <Header @changelangpopule='ischangelangpopule'></Header>
    <router-view />
    <Footer @changelangpopule='ischangelangpopule'></Footer>
    <GdprWrapper v-if="$store.state.setting.isShowCookies"></GdprWrapper>
    <Side v-if="isActive"></Side>
    <!-- 移动端语言弹框 -->
    <div class="mobile-lang-popule"
         :class="{ isopen: isLangToggleShow }">
      <div class="mobile_toggle">
        <div class="toggle_box"
             :class="{ active: isLangToggleShow }"
             @click="togglelangslider">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </div>
      </div>
      <div class="mobile-lang-box widthfix">
        <div class="lang-title">{{$t('header.text-选择您所在的国家或地区')}}</div>
        <div class="lang-list">
          <div class="item"
               v-for="(item,index) in $store.state.setting.langList"
               :key="index"
               @click="changeLang(item.key,item.name)">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import GdprWrapper from '@/components/gdprWrapper/index.vue'
import Side from '@/components/side/index.vue'
export default {
  // components: { Footer },
  components: { Header, Footer, GdprWrapper, Side },
  data () {
    return {
      isActive: false,
      isLangToggleShow: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    // 统计访客和下载量
    axios.get('https://api.wps.top/visits')
    // 获取设备
    this.getdevices()
  },
  beforeDestroy () {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    isLangToggleShow (value) {
      if (value) {
        this.$nextTick(() => {
          document.body.style.overflow = 'hidden'
        })
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  methods: {
    getdevices () {
      const userAgent = navigator.userAgent.toLowerCase()
      let os = ''

      if (/windows/i.test(userAgent)) {
        os = 'Windows'
      } else if (/macintosh|mac os x/i.test(userAgent)) {
        os = 'Mac'
      } else if (/linux/i.test(userAgent)) {
        os = 'Linux'
      } else if (/android/i.test(userAgent)) {
        os = 'Android'
      } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        os = 'iOS'
      }
      this.$store.commit('setting/SETDEVICESNAME', os)
    },
    handleScroll () {
      const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (top >= 300) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    changeLang (e, name) {
      // eslint-disable-next-line no-return-assign
      if (e === this.$store.state.setting.lang) { return this.isLangToggleShow = false }
      this.$i18n.locale = e
      this.$store.commit('setting/SETLANG', e)
      this.$store.commit('setting/SETLANGNAME', name)
      this.isLangToggleShow = false
    },
    ischangelangpopule (e) {
      this.isLangToggleShow = true
    },
    togglelangslider () {
      this.isLangToggleShow = !this.isLangToggleShow
    }
  }
}
</script>
<style lang="less">
.mobile-lang-popule {
  background: #f7f7f7;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  transition: all 0.3s;
  &.isopen {
    opacity: 1;
    visibility: visible;
  }
  .mobile_toggle {
    display: flex;
    justify-content: flex-end;
    padding: 0.9375rem 0.625rem 0.9375rem 0;

    .toggle_box {
      cursor: pointer;

      &.active {
        .icon-bar:nth-child(1) {
          opacity: 0;
        }

        .icon-bar:nth-child(2) {
          transform: rotate(45deg) translate(1px, 1px);
        }

        .icon-bar:nth-child(3) {
          transform: rotate(-45deg) translate(0.25rem, -0.25rem);
        }
      }

      .icon-bar {
        background: #222;
        display: block;
        width: 1.5625rem;
        height: 0.125rem;
        border-radius: 1px;
        margin-bottom: 0.3125rem;
        transition: all 0.3s;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .mobile-lang-box {
    margin: 1.875rem auto 0;
    .lang-title {
      font-size: 1.25rem;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 700;
    }
    .lang-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      .item {
        width: 50%;
        height: 2.75rem;
        line-height: 2.75rem;
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
</style>
