import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import setting from './modules/setting'
// 使用vuex-persistedstate插件来进行持久化
// npm install --save vuex-persistedstate
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    setting
  },
  getters,
  plugins: [
    // 配置持久化存储插件
    createPersistedstate({
      // 本地存储的键名称
      key: 'wps-h5',
      // 把 user 模块和 cart 模块的信息同步到本地存储
      paths: ['setting']
    })
  ]
})

export default store
