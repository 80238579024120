import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
Vue.use(VueI18n)
// npm install vue-i18n@8.26.8 --save
const i18n = new VueI18n({
  locale: store.state.setting.lang, // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    Japan: require('../VueI18n/language-Japan'), // 日本
    en: require('../VueI18n/language-en'), // 英文
    es: require('../VueI18n/language-es'), // 西班牙
    de: require('../VueI18n/language-de'), // 德语
    fr: require('../VueI18n/language-fr'), // 法国
    bs: require('../VueI18n/language-bs'), // 印度尼西亚语
    pl: require('../VueI18n/language-pl'), // 波兰
    br: require('../VueI18n/language-br'), // 葡萄牙
    ru: require('../VueI18n/language-ru'), // 俄语 因为还要改首页的样式 所以这个语言没有
    hi: require('../VueI18n/language-hi'), // 印地语
    tl: require('../VueI18n/language-tl'), // 菲律宾
    ms: require('../VueI18n/language-ms'), // 马来语
    it: require('../VueI18n/language-it'), // 意大利语
    th: require('../VueI18n/language-th'), // 泰国语
    vi: require('../VueI18n/language-vi') // 越南语
  }
})

export default i18n
