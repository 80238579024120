<template>
  <div class="header-page">
    <div class="header-box widthfix">
      <div class="logo-box"
           @click="$router.push('/')">
        <img src="@/assets/images/logo.png"
             alt="">
      </div>
      <div class="header-center">
        <div class="header-list">
          <div class="menu"
               @mouseenter="open = true"
               @mouseleave="open = false">
            <span class="menu-name">{{$t('header.nav-产品')}}</span>
            <span class="arrow"></span>
            <!-- 底部下拉 -->
            <div class="dropdown-container-fullscreen"
                 :class="{active:open}">
              <div class="dropdown-container-wrapper">
                <div class="dropdown-container">
                  <!-- 这个是product的内容 -->
                  <div class="dropdown-item">
                    <div class="row-menu">
                      <div class="menu-item">
                        <div class="submenu-content">
                          <div class="submenu-title">
                            <img src="@/assets/images/WPS_office.png"
                                 alt=""
                                 class="icon">
                            <div class="title-text">WPS Office</div>
                          </div>
                          <div class="submenu-box">
                            <div class="submenu">
                              <div class="submenu-item">
                                <router-link to="">
                                  {{ $t('header.nav-item-概述') }}
                                </router-link>
                              </div>
                              <div class="submenu-item">
                                <router-link to="/download"
                                             @click.native="open = false">
                                  WPS Office for Windows
                                </router-link>
                              </div>
                              <div class="submenu-item">
                                <router-link to="/download"
                                             @click.native="open = false">
                                  WPS Office for Mac
                                </router-link>
                              </div>
                              <div class="submenu-item">
                                <router-link to="/download"
                                             @click.native="open = false">
                                  WPS Office for Android
                                </router-link>
                              </div>
                              <div class="submenu-item">
                                <router-link to="/download"
                                             @click.native="open = false">
                                  WPS Office for iOS
                                </router-link>
                              </div>
                              <div class="submenu-item">
                                <router-link to="/download"
                                             @click.native="open = false">
                                  WPS Office for Linux
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link to="/download"
                       class="menu">
            <span class="menu-name">{{$t('header.nav-下载')}}</span>
          </router-link>
          <router-link to="/aiSpell"
                       class="menu">
            <span class="menu-name isGradientRed">{{$t('header.nav-AI拼写检测')}}</span>
            <img src="@/assets/images/star.png"
                 alt=""
                 class="top-icon">
          </router-link>
        </div>
      </div>
      <div class="header-right">
        <div class="lang">
          <div class="lang-img">
            <div class="lang-wrap">
              <img src="@/assets/images/language.png"
                   alt="">
            </div>
            <div class="lang-switch-wrapper">
              <div class="lang-switch-container">
                <div class="lang-list">
                  <div class="item"
               v-for="(item,index) in $store.state.setting.langList" :key="index"
               @click="changeLang(item.key,item.name)">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-header widthfix">
      <!-- 移动端的横条 -->
      <div class="toggle_box"
           @click="togglenavslider">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </div>
      <div class="mobile-logo"
           @click="$router.push('/')">
        <img src="@/assets/images/logo.png"
             alt="">
      </div>
      <div class="mobile-right"
           @click="toggleslider">
        <span></span>
      </div>
    </div>
    <!-- 用户弹框 -->
    <div class="mobile-user-popule"
         :class="{ isopen: isUserToggleShow }">
      <div class="mobile_toggle">
        <div class="toggle_box"
             :class="{ active: isUserToggleShow }"
             @click="toggleslider">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </div>
      </div>
      <div class="mobile-user-box">
        <div class="logoWrap">
          <div class="logo_img"></div>
          <div class="logo_desc">{{ $t('header.text-一个账户所有WPS服务') }}</div>
        </div>
        <div class="tplogin">
          <div class="tpItem_google">
            <span class="tpItem_c">
              <span class="icon"></span>
            </span>
            <span class="title">{{$t('header.text-使用Google登录')}}</span>
          </div>
          <div class="tpItem_google tpItem_email">
            <span class="tpItem_c">
              <span class="icon"></span>
            </span>
            <span class="title">{{$t('header.text-使用电子邮件登录')}}</span>
          </div>
        </div>
        <div class="js_toProtocolCheckbox">
          <span class="read_text">{{ $t('header.text-通过继续，我同意') }}</span>
          <router-link to=''
                       class="js_protocol_url"> {{ $t('header.text-在线服务用户协议') }}</router-link>
          <span> , </span>
          <router-link to="">{{ $t('header.text-WPS 365服务协议') }} </router-link>
          <span> , </span>
          <router-link to="">{{ $t('header.text-隐私政策') }}</router-link>
          <span> , </span>
          <router-link to="">{{ $t('header.text-人工智能隐私政策') }} </router-link>
          <span> {{ $t('header.text-和') }} </span>
          <router-link to=""> {{ $t('header.text-AI补充条款') }} </router-link>
        </div>
        <div class="login_bottom">
          <div class="bottom-link">
            <a href="https://www.facebook.com/dialog/oauth?client_id=986317108121171"
                         class="js_toProtocolDialog_TPLogin">
              <div class="circle">
                <span></span>
              </div>
              <span class="title">Facebook</span>
            </a>
            <router-link to=""
                         class="js_toProtocolDialog_TPLogin">
              <div class="circle">
                <span></span>
              </div>
              <span class="title">{{$t('header.text-更多')}}</span>
            </router-link>
          </div>
          <div class="pro_link">
            <span>{{$t('header.text-企业用户')}}？</span>
            <span class="pro_login">{{$t('header.text-在此处登录')}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端左侧导航栏 -->
    <div class="mobile-nav-popule"
         :class="{'slidermobileactive':isNavToggleShow}">
      <div class="mobile-nav-box">
        <div class="nav-left">
          <div class="mobile_toggle">
            <div class="toggle_box"
                 :class="{ active: isNavToggleShow }"
                 @click="togglenavslider">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </div>
          </div>
          <div class="menu-list">
            <div class="menu">
              <router-link to=""
                           class="menu-name"
                           @click.native="ischange(1)">
                {{$t('header.nav-产品')}}
                <img src="@/assets/images/menu-arrow-new.svg"
                     alt=""
                     class="arrow"
                     :class="{active:istype==1}">
              </router-link>
              <div class="dropdown-container"
                   v-if="istype==1">
                <div class="dropdown-item">
                  <div class="submenu-content">
                    <div class="submenu-title">
                      <div class="title-text">WPS Office</div>
                    </div>
                    <div class="submenu-box">
                      <div class="submenu">
                        <router-link to=''
                                     class="submenu-item">{{ $t('header.nav-item-概述') }}
                          <!-- <img src="@/assets/images/web19.png"
                               alt=""
                               class="icon"> -->
                        </router-link>
                        <router-link to='/download'
                                     class="submenu-item"
                                     @click.native="togglenavslider">WPS Office for Windows</router-link>
                        <router-link to='/download'
                                     class="submenu-item"
                                     @click.native="togglenavslider">WPS Office for Mac</router-link>
                        <router-link to='/download'
                                     class="submenu-item"
                                     @click.native="togglenavslider">WPS Office for Android</router-link>
                        <router-link to='/download'
                                     class="submenu-item"
                                     @click.native="togglenavslider">WPS Office for ios</router-link>
                        <router-link to='/download'
                                     class="submenu-item"
                                     @click.native="togglenavslider">WPS Office for linux</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="menu">
              <router-link to="/download"
                           class="menu-name"
                           @click.native="togglenavslider">{{$t('header.nav-下载')}}</router-link>
            </div>
            <div class="menu">
              <router-link to="/aiSpell"
                           class="menu-name star"
                           @click.native="togglenavslider">
                {{$t('header.nav-AI拼写检测')}}
                <img src="@/assets/images/star.png"
                     alt=""
                     class="star-icon">
              </router-link>
            </div>
          </div>
          <div class="lang-switch-trigger"
               @click='changepopulelang'>
            <div class="language-btn">
              <img src="@/assets/images/language.png"
                   alt=""
                   class="earth-img" />
              <span class="current-language">{{$store.state.setting.langName}}</span>
              <img src="@/assets/images/menu-arrow-new.svg"
                   alt=""
                   class="arrow" />
            </div>
          </div>
          <div class="btn-wrapper">
            <a :href="'https://api.wps.top/download?pack='+$store.state.setting.devicesName" class="try-btn">
              {{$t('global.text-免费下载')}}
            </a>
          </div>
        </div>
        <div class="nav-right"
             @click="togglenavslider"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerPage',
  data () {
    return {
      isUserToggleShow: false,
      isNavToggleShow: false,
      istype: 0,
      open: false
    }
  },
  watch: {
    isUserToggleShow (value) {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
    isNavToggleShow (value) {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  methods: {
    changeLang (e, name) {
      if (e === this.$store.state.setting.lang) return
      this.$i18n.locale = e
      this.$store.commit('setting/SETLANG', e)
      this.$store.commit('setting/SETLANGNAME', name)
    },
    changepopulelang () {
      this.isNavToggleShow = false
      this.$emit('changelangpopule')
    },
    ischange (index) {
      if (index === this.istype) {
        this.istype = 0
      } else {
        this.istype = index
      }
    },
    toggleslider () {
      this.isUserToggleShow = !this.isUserToggleShow
    },
    togglenavslider () {
      this.isNavToggleShow = !this.isNavToggleShow
      this.istype = 0
    }
  }
}
</script>

<style lang="less" scoped>
.header-page {
  height: 62px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  box-shadow: 0 12px 64px 0 rgba(154, 171, 233, 0.16);

  .header-box {
    display: flex;
    height: 62px;

    .logo-box {
      display: flex;
      align-items: center;
      padding-right: 43px;
      cursor: pointer;

      img {
        width: 96px;
      }
    }

    .header-center {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-list {
        .menu {
          display: inline-block;
          margin: 0 16px;
          padding: 8px;
          color: #000;
          font-size: 14px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: -12px;
            bottom: -12px;
            right: -8px;
            left: -8px;
            cursor: pointer;
          }

          &:hover {
            border-radius: 8px;
            background: rgba(13, 13, 13, 0.06);
            .arrow {
              transform: rotateX(-180deg);
            }
          }

          .menu-name {
            font-weight: 500;
          }

          .isGradientRed {
            background-image: -webkit-linear-gradient(180deg, #956aff, #ff2b37);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .top-icon {
            width: 8.5px;
            height: 8px;
            position: relative;
            top: -7px;
            left: 7px;
          }

          .arrow {
            background-image: url("@/assets/images/arrow.png");
            width: 16px;
            height: 10px;
            margin-left: 2px;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            transition: transform 0.3s ease-in-out;
            display: inline-block;
          }
        }
      }

      .dropdown-container-fullscreen {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.6, 1) 80ms;
        position: fixed;
        width: 100%;
        // height: 100%;
        background: rgba(232, 232, 237, 0.1);
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
        top: 62px;
        left: 0;
        &.active {
          opacity: 1;
          visibility: visible;
        }

        .dropdown-container-wrapper {
          background: #fff;
          position: absolute;
          left: 0;
          right: 0;
          box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;

          .dropdown-container {
            background: #fff;
            display: flex;
            padding-left: 267px;

            .dropdown-item {
              padding: 30px 12px 42px;

              &:last-child {
                .row-menu {
                  border-right: none;
                }
              }

              .row-menu {
                border-right: 1px solid #f4f4f8;
                height: 100%;

                .menu-item {
                  margin-bottom: 26px;

                  .submenu-content {
                    padding: 0 23px 0 0;

                    .submenu-title {
                      display: flex;
                      align-items: center;
                      margin: 0 0 12px;

                      .icon {
                        width: 28px;
                        height: 28px;
                        margin-right: 12px;
                      }

                      .title-text {
                        font-size: 14px;
                        font-weight: 700;
                        color: rgba(0, 0, 0, 0.6);
                        line-height: 21px;
                        padding: 7px 0 9px;
                      }

                      .sub-icon {
                        width: 22px;
                        height: 11px;
                        position: relative;
                        left: 4px;
                      }
                    }

                    .submenu-box {
                      display: flex;

                      .submenu {
                        .submenu-item {
                          margin-left: 28px;
                          display: flex;
                          align-items: center;
                          cursor: pointer;

                          &:hover {
                            background: rgba(64, 72, 115, 0.05);
                            border-radius: 6px;

                            a {
                              text-shadow: 0 0 1px #959595, 0 0 0.4px #a1a1a1;
                              color: rgba(0, 0, 0, 0.7);
                            }

                            .icon-arrow {
                              display: block;
                            }
                          }

                          .more {
                            .more-title {
                              color: rgb(54, 132, 228);
                              text-decoration: underline;
                            }

                            .more-arrow {
                              color: rgb(54, 132, 228);
                            }
                          }

                          a {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.5);
                            line-height: 19px;
                            vertical-align: middle;
                            padding: 11px 12px 12px;

                            .icon {
                              width: 20px;
                              height: 20px;
                              margin-left: 6px;
                            }

                            .icon-arrow {
                              width: 15px;
                              height: 17px;
                              margin-left: 6px;
                              margin-bottom: 2px;
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .header-right {
      display: flex;
      align-items: center;

      .lang {
        margin-right: 36px;
        height: 100%;

        .lang-img {
          position: relative;
          height: 100%;
          cursor: pointer;
          &:hover {
            .lang-switch-wrapper {
              opacity: 1;
              visibility: visible;
            }
          }
          .lang-wrap {
            display: flex;
            align-items: center;
            height: 100%;
            img {
              width: 24px;
            }
          }
          .lang-switch-wrapper {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s cubic-bezier(0.4, 0, 0.6, 1) 80ms;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            border-radius: 9px;
            border: 1px solid rgba(0, 0, 0, 0.15);

            .lang-switch-container {
              .lang-list {
                width: 272px;
                padding: 12px 24px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;

                &::after {
                  position: absolute;
                  display: table;
                  content: "";
                  width: 12px;
                  height: 6px;
                  background-image: url("@/assets/images/hover_arrow.svg");
                  background-repeat: no-repeat;
                  top: -6px;
                  left: 50%;
                  transform: translateX(-50%);
                }

                .item {
                  width: 137px;

                  &:nth-child(2n) {
                    width: 86px;
                  }

                  height: 44px;
                  line-height: 44px;
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.8);

                  &:hover {
                    color: rgba(0, 0, 0, 0.4);
                  }
                }
              }
            }
          }
        }
      }

      .download-btn-wrapper {
        span {
          border-radius: 8px;
          padding: 0 16px;
          min-width: 101px;
          height: 38px;
          line-height: 38px;
          background-color: #333;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          text-align: center;
          color: #fff;
          box-shadow: 0 1px 3px 0 rgba(0, 79, 201, 0.1);
          display: inline-block;

          &:hover {
            background-color: #0d0d0d;
          }
        }
      }

      .sign-in {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          padding: 0 16px;
          max-width: 142px;
          min-width: 48px;
          height: 36px;
          border-radius: 8px;
          border: 1px solid #333;
          cursor: pointer;
          margin-left: 16px;

          &:hover {
            background: rgba(13, 13, 13, 0.06);
          }
        }
      }
    }
  }

  .mobile-header {
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 3.875rem;
    box-shadow: 0 0.75rem 4rem 0 rgba(154, 171, 233, 0.16);

    .header-box {
      display: none;
    }

    .mobile-header {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      height: 3.875rem;
      width: 100%;

      .toggle_box {
        cursor: pointer;

        &.active {
          .icon-bar:nth-child(1) {
            opacity: 0;
          }

          .icon-bar:nth-child(2) {
            transform: rotate(45deg) translate(1px, 1px);
          }

          .icon-bar:nth-child(3) {
            transform: rotate(-45deg) translate(0.25rem, -0.25rem);
          }
        }

        .icon-bar {
          background: #222;
          display: block;
          width: 0.9375rem;
          height: 0.125rem;
          border-radius: 1px;
          margin-bottom: 0.3125rem;
          transition: all 0.3s;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .mobile-logo {
        img {
          width: 5rem;
          height: 1.25rem;
        }
      }

      .mobile-right {
        span {
          display: inline-block;
          width: 1.75rem;
          height: 1.75rem;
          vertical-align: middle;
          border-radius: 50%;
          background-image: url(@/assets/images/header_M_profile.svg);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
  .mobile-user-popule {
    background: #f7f7f7;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    transition: all 0.3s;
    &.isopen {
      opacity: 1;
      visibility: visible;
    }
    .mobile_toggle {
      display: flex;
      justify-content: flex-end;
      padding: 0.9375rem 0.625rem 0.9375rem 0;

      .toggle_box {
        cursor: pointer;

        &.active {
          .icon-bar:nth-child(1) {
            opacity: 0;
          }

          .icon-bar:nth-child(2) {
            transform: rotate(45deg) translate(1px, 1px);
          }

          .icon-bar:nth-child(3) {
            transform: rotate(-45deg) translate(0.25rem, -0.25rem);
          }
        }

        .icon-bar {
          background: #222;
          display: block;
          width: 1.5625rem;
          height: 0.125rem;
          border-radius: 1px;
          margin-bottom: 0.3125rem;
          transition: all 0.3s;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .mobile-user-box {
      width: 16.25rem;
      margin: 1.875rem auto 0;
      .logoWrap {
        text-align: center;
        .logo_img {
          height: 2.3125rem;
          background: url("@/assets/images/mobilelogo.png") center center
            no-repeat;
          background-size: 7.875rem;
        }
        .logo_desc {
          font-size: 0.75rem;
          color: #606263;
          margin-top: 0.25rem;
        }
      }
      .tplogin {
        margin-top: 4.375rem;
        .tpItem_google,
        .tpItem_email {
          border-radius: 0.25rem;
          line-height: 2.75rem;
          color: #fff;
          border: 1px solid #4285f4;
          height: 2.625rem;
          position: relative;
          color: #fff;
          overflow: hidden;
          .tpItem_c {
            position: absolute;
            width: 3.25rem;
            height: 100%;
            left: 0;
            top: 0;
            text-align: center;
            line-height: 2.75rem;
            border-right-color: #4285f4;
            .icon {
              display: inline-block;
              width: 1.3125rem;
              height: 1.3125rem;
              background-image: url("@/assets/images/google.png");
              background-size: 1.3125rem;
              margin-top: 0.625rem;
            }
          }

          .title {
            background-color: #4285f4;
            position: absolute;
            left: 3.3125rem;
            right: 0;
            text-indent: 1.375rem;
            text-align: left;
            height: 100%;
            font-size: 0.875rem;
          }
        }
        .tpItem_email {
          border: 1px solid #d8d8d8;
          margin-top: 1.25rem;
          background-color: #fff;
          .tpItem_c {
            border-right: 1px solid #d8d8d8;
            .icon {
              background-image: url("@/assets/images/email_icon.svg");
              margin-top: 0.4375rem;
              width: 1.75rem;
              height: 1.75rem;
              background-size: 1.75rem;
            }
          }
          .title {
            background-color: #fff;
            color: #535252;
          }
        }
      }
      .js_toProtocolCheckbox {
        font-size: 0.75rem;
        text-align: left;
        color: #aaa;
        margin-top: 1.125rem;
        line-height: 1.25rem;
        a {
          padding: 0 0.125rem;
          color: #1e86e7;
          text-decoration: underline;
        }
      }
      .login_bottom {
        text-align: center;
        margin-top: 9.375rem;
        .bottom-link {
          display: flex;
          justify-content: center;
          .js_toProtocolDialog_TPLogin {
            color: #535252;
            text-align: center;
            .circle {
              width: 2.8125rem;
              height: 2.8125rem;
              border-radius: 50%;
              background: #fff;
              margin: 0 1rem 0.5rem;
              position: relative;
              span {
                left: 0.8125rem;
                top: 0.8125rem;
                bottom: 0.8125rem;
                right: 0.8125rem;
                position: absolute;
                background: url("@/assets/images/facebook.svg") no-repeat;
                background-size: 1.1875rem;
              }
            }
            .title {
              font-size: 0.875rem;
            }
            &:nth-child(2) {
              .circle {
                span {
                  background: url("../../assets/images/login.png") no-repeat;
                  background-size: 1.5rem;
                  margin-top: 0.375rem;
                  width: 1.375rem;
                  height: 0.375rem;
                  background-position: 0 -11.9375rem;
                }
              }
            }
          }
        }
        .pro_link {
          font-size: 0.75rem;
          padding: 1.875rem 0 1.25rem;
          span {
            color: #535252;
          }
          .pro_login {
            color: #1e86e7;
          }
        }
      }
    }
  }
  .mobile-nav-popule {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    transition: all 0.3s;
    transform: translateX(-100%);
    &.slidermobileactive {
      transform: translateX(0);
    }
    .mobile-nav-box {
      display: flex;
      height: 100%;
      .nav-left {
        padding: 0 1.25rem 0 0.9375rem;
        width: calc(100vw - 4.375rem);
        background-color: #fff;
        overflow-y: auto;
        box-shadow: 0 0.25rem 3rem 0 hsla(0, 0%, 69%, 0.25);
        .mobile_toggle {
          padding: 0.9375rem 0.625rem 0.9375rem 0;

          .toggle_box {
            cursor: pointer;

            &.active {
              .icon-bar:nth-child(1) {
                opacity: 0;
              }

              .icon-bar:nth-child(2) {
                transform: rotate(45deg) translate(1px, 1px);
              }

              .icon-bar:nth-child(3) {
                transform: rotate(-45deg) translate(0.25rem, -0.25rem);
              }
            }

            .icon-bar {
              background: #222;
              display: block;
              width: 1.5625rem;
              height: 0.125rem;
              border-radius: 1px;
              margin-bottom: 0.3125rem;
              transition: all 0.3s;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .menu-list {
          .menu {
            font-size: 1rem;
            font-weight: 700;
            color: #000;
            .menu-name,
            .star {
              position: relative;
              height: 3.375rem;
              line-height: 3.375rem;
              display: block;
              color: #000;
              font-weight: 700;
              border-bottom: 1px solid #ebecee;
              padding: 0 0.5rem;
              .arrow {
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: 1.1875rem;
                right: 0;
                transition: transform 0.2s ease-in-out;
                &.active {
                  transform: rotate(90deg);
                }
              }
            }
            .star {
              background: linear-gradient(180deg, #956aff, #ff2b37);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .star-icon {
              width: 0.5rem;
              height: 0.5rem;
              position: relative;
              top: -0.4375rem;
            }
            .dropdown-container {
              .dropdown-item {
                .submenu-content {
                  padding-right: 1.4375rem;
                  .submenu-title {
                    margin-top: 0.75rem;
                    display: flex;
                    align-items: center;
                    .title-text {
                      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
                      color: #000;
                      font-size: 0.875rem;
                      font-weight: 700;
                    }
                    img {
                      width: 1.375rem;
                      height: 0.6875rem;
                      position: relative;
                      left: 0.25rem;
                    }
                  }
                  .submenu-box {
                    .submenu {
                      .submenu-item {
                        padding: 0.625rem 0.75rem;
                        color: rgba(0, 0, 0, 0.7);
                        font-size: 0.875rem;
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        .icon,
                        .icon-phone {
                          width: 1.25rem;
                          height: 1.25rem;
                          flex-shrink: 0;
                          margin-left: 0.375rem;
                        }
                        .icon-phone {
                          width: 0.625rem;
                          height: 0.875rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .lang-switch-trigger {
          margin-bottom: 12.5rem;
          .language-btn {
            display: inline-block;
            font-size: 0.875rem;
            color: rgba(0, 0, 0, 0.6);
            line-height: 1.1875rem;
            padding: 0.5625rem 0.75rem;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;

            .earth-img {
              width: 1rem;
              height: 1rem;
              margin-right: 0.5rem;
              vertical-align: -0.1875rem;
            }

            .current-language {
            }

            .arrow {
              width: 0.625rem;
              height: 0.625rem;
              margin-left: 0.375rem;
              vertical-align: middle;
            }
          }
        }
        .btn-wrapper {
          flex: 8;
          position: fixed;
          bottom: 0;
          left: 0;
          width: calc(100vw - 4.375rem);
          padding: 1rem 1rem 1.75rem;
          background: #fff;
          z-index: 10;
          .try-btn {
            display: block;
            background-color: rgba(0, 0, 0, 0.8);
            text-align: center;
            color: #fff;
            border-radius: 0.375rem;
            box-shadow: 0 1px 0.1875rem 0 rgba(0, 79, 201, 0.1);
            height: 3.375rem;
            line-height: 3.375rem;
            padding: 0 1.875rem;
            height: 3rem;
            line-height: 3rem;
            font-size: 0.875rem;
          }
        }
      }
      .nav-right {
        flex: 1;
      }
    }
  }
}
</style>
