<template>
  <div class="side-page">
    <div class="munu-item">
      <div class="desc">{{$t('side.text-加入我们的社区')}}</div>
      <div class="icon-box">
        <img src="@/assets/images/Community.png" alt="">
      </div>
    </div>
    <div class="munu-item">
      <div class="desc">{{$t('side.text-WPS学院')}}</div>
      <div class="icon-box">
        <img src="@/assets/images/WpsAcademy.png" alt="">
      </div>
    </div>
    <div class="munu-item">
      <div class="desc">{{$t('side.text-反馈')}}</div>
      <div class="icon-box">
        <img src="@/assets/images/Feedback.png" alt="">
      </div>
    </div>
    <div class="munu-item" @click="backTop">
      <div class="desc">{{$t('side.text-返回顶部')}}</div>
      <div class="icon-box">
        <img src="@/assets/images/Backtotop.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidePage',
  methods: {
    backTop () {
      // 开个定时器返回顶部时缓慢优雅一些，是 document.documentElement.scrollTop = 0 的升级版
      const timer = setInterval(() => {
        // 页面顶部卷去高度
        const scrollTop = document.documentElement.scrollTop
        // 高度控速 = 页面顶部卷去高度正值 / n ===> n 越大，返回越慢
        const ispeed = Math.floor(-scrollTop / 5)
        // 页面顶部卷去高度 + 高度控速 ===> 和不为零，定时器一直开启（不明白的同志可以尝试同时打印 scrollTop 和 ispeed）
        document.documentElement.scrollTop = scrollTop + ispeed
        // 页面顶部卷去高度 + 高度控速 ===> 和为零返回顶部，清除定时器
        if (scrollTop === 0) {
          clearInterval(timer)
        }
      }, 20)
      // let top = document.documentElement.scrollTop || document.body.scrollTop
      // const timeTop = setInterval(() => {
      //   document.body.scrollTop =
      //     document.documentElement.scrollTop =
      //     top -=
      //     50
      //   if (top <= 0) {
      //     clearInterval(timeTop)
      //   }
      // }, 10)
    }
  }
}
</script>

<style lang="less" scoped>
.side-page {
  position: fixed;
  z-index: 10;
  right: 30px;
  bottom: 142px;

  .munu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 13px 0;
    justify-content: flex-end;
    color: #fff;

    &:hover {
      .desc {
        display: block;
      }
    }

    .desc {
      height: 29px;
      padding: 0 11px;
      background: #333;
      position: relative;
      margin-right: 12px;
      border-radius: 3px;
      line-height: 29px;
      font-size: 12px;
      display: none;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #333;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
      }
    }

    .icon-box {
      width: 42px;
      height: 42px;
      background: #fff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1);
      position: relative;
      border-radius: 50%;

      img {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
