import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './VueI18n'
// 样式重置
import '@/assets/styles/common.less'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
