const state = {
  isShowCookies: true,
  lang: 'en',
  langName: 'English',
  langList: [
    { key: 'en', name: 'English' },
    { key: 'de', name: 'Deutsch' },
    { key: 'es', name: 'Español' },
    { key: 'fr', name: 'Français' },
    { key: 'bs', name: 'Bahasa Indonesia' },
    { key: 'pl', name: 'Polskie' },
    { key: 'br', name: 'Português' },
    { key: 'hi', name: 'हिंदी' },
    { key: 'tl', name: 'Filipino' },
    { key: 'ms', name: 'Melayu' },
    { key: 'it', name: 'Italiano' },
    { key: 'Japan', name: '日本語' },
    { key: 'th', name: 'ไทย' },
    { key: 'vi', name: 'Tiếng Việt' }
  ],
  devicesName: '' // 终端名称
}

const mutations = {
  SETSHOWCOOKIES (state, data) {
    state.isShowCookies = data
  },
  SETLANG (state, data) {
    state.lang = data
  },
  SETLANGNAME (state, data) {
    state.langName = data
  },
  SETDEVICESNAME (state, data) {
    state.devicesName = data
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
