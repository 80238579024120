<template>
  <div class="footer-page"
       :class="{cookiesActive:!$store.state.setting.isShowCookies}">
    <div class="container-footer widthfix">
      <div class="footer-nav-row">
        <div class="logo-wrapper">
          <img src="@/assets/images/logo.png"
               alt="" />
          <div class="logo-title">{{ $t('footer.text-让忙碌的工作变得轻松') }}</div>
          <div class="logo-des">
            WPS SOFTWARE PTE. LTD., 6 RAFFLES QUAY #14-06 SINGAPORE (048580)
          </div>
        </div>
        <div class="footer-nav-group">
          <div class="menu-box">{{ $t('header.nav-产品') }}</div>
          <div class="footer-link-wrapper">
            <div class="footer-link-box">
              <router-link to="/download">WPS Office for Windows</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/download">WPS Office for Mac</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/download">WPS Office for Android</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/download">WPS Office for iOS</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/download">WPS Office for Linux</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/download">{{$t('footer.text-wps教育办公室')}}</router-link>
            </div>
          </div>
        </div>
        <div class="footer-nav-group">
          <div class="menu-box">Company</div>
          <div class="footer-link-wrapper">
            <div class="footer-link-box">
              <router-link to="/about">{{ $t('footer.text-关于我们') }}</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/partner">{{ $t('footer.text-业务合作') }}</router-link>
            </div>
            <div class="footer-link-box">
              <router-link to="/privacy">{{ $t('footer.text-隐私政策') }}</router-link>
            </div>
          </div>
        </div>
        <!-- <div class="footer-nav-group">
          <div class="menu-box">Support</div>
          <div class="footer-link-wrapper">
            <div class="footer-link-box">
              <router-link to="">Feedback</router-link>
            </div>
          </div>
        </div> -->
        <div class="footer-nav-group">
          <div class="menu-box">{{ $t('footer.text-关注我们') }}</div>
          <div class="footer-media">
            <a href="https://www.facebook.com/kingsoftwps"
               target="_black">
              <img src="@/assets/images/circle_facebook.svg"
                   alt="" />
            </a>
            <a href="https://twitter.com/WPS_Office"
               target="_black">
              <img src="@/assets/images/circle_twitter.svg"
                   alt="" />
            </a>
            <a href="https://www.youtube.com/wpsofficeofficial"
               target="_black">
              <img src="@/assets/images/circle_youtube.svg"
                   alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="language-box">
        <div class="language-btn"
             @click.stop="isLang = !isLang">
          <img src="@/assets/images/language.png"
               alt=""
               class="earth-img" />
          <span class="current-language">{{$store.state.setting.langName}}</span>
          <img src="@/assets/images/arrow_gray.svg"
               alt=""
               class="arrow"
               :class="{ active: isLang }" />
          <div class="lang-switch-wrapper"
               v-if="isLang">
            <div class="lang-switch-container">
              <div class="lang-list">
                <div class="item"
               v-for="(item,index) in $store.state.setting.langList" :key="index"
               @click="changeLang(item.key,item.name)">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <p>Copyright © Kingsoft Office Software, All Rights Reserved.</p>
      </div>
    </div>
    <div class="mobile-container-footer">
      <div class="logo-wrapper widthfix">
        <div class="logo-wrapper-top">
          <img src="@/assets/images/logo.png"
               alt="" />
          <p class="text">{{ $t('footer.text-让忙碌的工作变得轻松') }}</p>
          <p class="logo-info">
            WPS SOFTWARE PTE. LTD., 6 RAFFLES QUAY #14-06 SINGAPORE (048580)
          </p>
        </div>
        <div class="footer-nav-group">
          <div class="menu-box"
               @click="istype1=!istype1">
            <span class="font-text">{{ $t('header.nav-产品') }}</span>
            <div class="arrow"
                 :class="{active:istype1}"></div>
          </div>
          <div class="footer-link-wrapper"
               v-if="istype1">
            <router-link to="/download"
                         class="footer-link-box">
              <span>WPS Office for Windows</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to="/download"
                         class="footer-link-box">
              <span>WPS Office for Mac</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to="/download"
                         class="footer-link-box">
              <span>WPS Office for Android</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to="/download"
                         class="footer-link-box">
              <span>WPS Office for iOS</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to="/download"
                         class="footer-link-box">
              <span>WPS Office for Linux</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
          </div>
        </div>
        <div class="footer-nav-group">
          <div class="menu-box"
               @click="istype2=!istype2">
            <span class="font-text">Company</span>
            <div class="arrow"
                 :class="{active:istype2}"></div>
          </div>
          <div class="footer-link-wrapper"
               v-if="istype2">
            <router-link to="/about"
                         class="footer-link-box">
              <span>{{ $t('footer.text-关于我们') }}</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to="/partner"
                         class="footer-link-box">
              <span>{{ $t('footer.text-业务合作') }}</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to="/privacy"
                         class="footer-link-box">
              <span>{{ $t('footer.text-隐私政策') }}</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
          </div>
        </div>
        <!-- <div class="footer-nav-group">
          <div class="menu-box"
               @click="istype3=!istype3">
            <span class="font-text">Support</span>
            <div class="arrow"
                 :class="{active:istype3}"></div>
          </div>
          <div class="footer-link-wrapper"
               v-if="istype3">
            <router-link to=""
                         class="footer-link-box">
              <span>Feedback</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to=""
                         class="footer-link-box">
              <span>Help Center</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to=""
                         class="footer-link-box">
              <span>WPS Academy</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to=""
                         class="footer-link-box">
              <span>What's New</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
            <router-link to=""
                         class="footer-link-box">
              <span>Tech Specs</span>
              <img src="@/assets/images/arrow-new_gray.svg"
                   alt="" />
            </router-link>
          </div>
        </div> -->
        <div class="footer-us">
          <div class="title">{{ $t('footer.text-关注我们') }}</div>
          <div class="footer-media">
            <a href="https://www.facebook.com/kingsoftwps"
               target="_black">
              <img src="@/assets/images/circle_facebook.svg"
                   alt="" />
            </a>
            <a href="https://twitter.com/WPS_Office"
               target="_black">
              <img src="@/assets/images/circle_twitter.svg"
                   alt="" />
            </a>
            <a href="https://www.youtube.com/wpsofficeofficial"
               target="_black">
              <img src="@/assets/images/circle_youtube.svg"
                   alt="" />
            </a>
          </div>
        </div>
        <div class="language-box">
          <div class="language-btn"
               @click='mobilechangelangpouple'>
            <img src="@/assets/images/language.png"
                 alt=""
                 class="earth-img" />
            <span class="current-language">{{$store.state.setting.langName}}</span>
            <img src="@/assets/images/arrow_gray.svg"
                 alt=""
                 class="arrow" />
          </div>
          <p>Copyright © Kingsoft Office Software, All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerPage',
  data () {
    return {
      isLang: false,
      istype1: false,
      istype2: false,
      istype3: false
    }
  },
  mounted () {
    document.addEventListener('click', this.hideClick)
  },
  methods: {
    // 直接回到顶部
    toTop () {
      document.documentElement.scrollTop = 0
    },
    changeLang (e, name) {
      if (e === this.$store.state.setting.lang) return
      this.$i18n.locale = e
      this.$store.commit('setting/SETLANG', e)
      this.$store.commit('setting/SETLANGNAME', name)
      this.toTop()
    },
    mobilechangelangpouple () {
      this.$emit('changelangpopule')
    },
    hideClick () {
      if (this.isLang) {
        this.isLang = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer-page {
  padding: 54px 0 172px;
  &.cookiesActive {
    padding-bottom: 70px;
  }

  .container-footer {
    .footer-nav-row {
      display: flex;
      justify-content: space-between;

      .logo-wrapper {
        img {
          width: 91px;
          height: 24px;
          margin-bottom: 16px;
        }

        .logo-title {
          font-weight: 600;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 12px;
        }

        .logo-des {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .footer-nav-group {
        .menu-box {
          padding-left: 12px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.7);
          margin-bottom: 12px;
          font-weight: 600;
        }

        .footer-link-wrapper {
          .footer-link-box {
            border-radius: 3px;
            cursor: pointer;

            &:hover {
              background: rgba(64, 72, 115, 0.05);
            }

            a {
              font-size: 13px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.6);
              padding: 12px;
              display: inline-block;
              width: 100%;
            }
          }
        }

        .footer-media {
          padding-left: 12px;
          margin-top: 22px;
          a {
            display: inline-block;
            &:nth-child(2) {
              margin: 0 20px;
            }
            img {
              width: 36px;
              height: 36px;
              cursor: pointer;
            }
            &:hover {
              img {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }

    .language-box {
      .language-btn {
        display: inline-block;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 19px;
        padding: 9px 12px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;

        &:hover {
          .earth-img,
          .current-language,
          .arrow {
            opacity: 0.6;
          }
        }

        .lang-switch-wrapper {
          position: absolute;
          bottom: 49px;
          left: 0;
          background: #fff;
          border-radius: 9px;
          border: 1px solid rgba(0, 0, 0, 0.15);

          .lang-switch-container {
            .lang-list {
              width: 272px;
              padding: 12px 24px;
              box-sizing: border-box;
              display: flex;
              flex-wrap: wrap;

              &::after {
                position: absolute;
                display: table;
                content: "";
                width: 12px;
                height: 6px;
                background-image: url("@/assets/images/hover_arrow.svg");
                background-repeat: no-repeat;
                top: auto;
                bottom: -6px;
                transform: rotateX(-180deg);
                left: 18%;
              }

              .item {
                width: 137px;

                &:nth-child(2n) {
                  width: 86px;
                }

                height: 44px;
                line-height: 44px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.8);

                &:hover {
                  color: rgba(0, 0, 0, 0.4);
                }
              }
            }
          }
        }

        .earth-img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          vertical-align: -3px;
        }

        .current-language {
        }

        .arrow {
          width: 8px;
          height: 5px;
          margin-left: 4px;
          vertical-align: middle;

          &.active {
            transform: rotateX(-180deg);
            transition: transform 0.3s ease-in-out;
          }
        }
      }

      p {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 21px;
      }
    }
  }
  .mobile-container-footer {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding: 3.375rem 0 18.875rem;
    &.cookiesActive {
      padding-bottom: 4.375rem;
    }
    .container-footer {
      display: none;
    }
    .mobile-container-footer {
      display: block;
      .logo-wrapper {
        .logo-wrapper-top {
          margin-bottom: 2.5rem;
          img {
            margin-bottom: 1.4375rem;
            width: 5.625rem;
            height: 1.5rem;
          }
          .text {
            font-weight: 600;
            font-size: 0.8125rem;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 0.75rem;
          }
          .logo-info {
            font-size: 0.8125rem;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        .footer-nav-group {
          .menu-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 3.375rem;
            line-height: 3.375rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            font-size: 0.875rem;
            color: rgba(0, 0, 0, 0.7);
            .font-text {
              font-weight: 600;
            }
            .arrow {
              width: 0.875rem;
              height: 0.875rem;
              background-image: url(@/assets/images/arrow-new..svg);
              background-size: 100%;
              background-repeat: no-repeat;
              &.active {
                transform: rotate(180deg);
              }
            }
          }

          .footer-link-wrapper {
            padding-left: 1.125rem;
            background: #fff;
            .footer-link-box {
              padding: 0.875rem 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              display: inline-block;
              width: 100%;
              display: flex;
              justify-content: space-between;
              &:last-child {
                border-bottom: none;
              }
              span {
                color: rgba(0, 0, 0, 0.8);
                font-size: 0.875rem;
              }
              img {
              }
            }
          }
        }
        .footer-us {
          .title {
            font-size: 0.875rem;
            color: rgba(0, 0, 0, 0.7);
            line-height: 3.375rem;
          }
          .footer-media {
            a {
              display: inline-block;
              width: 2.25rem;
              height: 2.25rem;
              &:nth-child(2) {
                margin: 0 1.25rem;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .language-box {
          margin-top: 2.3125rem;
          .language-btn {
            display: inline-block;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 0.5rem;
            font-size: 0.875rem;
            color: rgba(0, 0, 0, 0.6);
            line-height: 1.1875rem;
            padding: 0.5625rem 0.75rem;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;

            .earth-img {
              width: 1rem;
              height: 1rem;
              margin-right: 0.5rem;
              vertical-align: -0.1875rem;
            }

            .current-language {
            }

            .arrow {
              width: 0.5rem;
              height: 0.3125rem;
              margin-left: 0.25rem;
              vertical-align: middle;

              &.active {
                transform: rotateX(-180deg);
                transition: transform 0.3s ease-in-out;
              }
            }
          }

          p {
            font-size: 0.75rem;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 1.3125rem;
          }
        }
      }
    }
  }
}
</style>
